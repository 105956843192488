<template>
  <div class="content-div">
    <h1>Assessment Guideline </h1>
    <p class="mt-3">To enhance transparency as well as to help the trade and the public better understand the factors that are taken into account by the Liquor Licensing Board (LLB) when assessing liquor licence applications, the LLB has prepared a set of guidelines that capture the criteria used in vetting liquor licence applications. <span style="color:#4e45d1;font-weight:500">The Guidelines on Assessing Liquor Licence Applications</span> (the Guidelines) do not carry the force of law and, as such, are not meant to bind LLB members. When considering the merits of individual applications, it is incumbent upon the LLB to give a full and fair hearing to each case in accordance with the requirements under the legislation. The LLB will keep the Guidelines under review and will update them as necessary.</p>
    <p class="mt-3">The factors set out in the Guidelines have been developed from the three criteria stipulated under Section 17(2) of the Dutiable Commodities (Liquor) Regulations (Cap. 109B), namely whether the applicant is a fit and proper person to hold the licence, whether the premises are suitable for the sale of liquor, and whether the grant of the licence is contrary to the public interest. In general, the criteria for assessing liquor licence applications are applicable to all types of liquor-licensed premises. Bearing in mind the fact that "upstairs bars" 1 are relatively more prone to attract public concerns, we have, under each of the three major criteria in the Guidelines, set out the factors that the LLB would specifically take into account when vetting liquor licence applications from "upstairs bars".</p>
    <p class="mt-3">Most of the criteria cited in the Guidelines have all along been adopted by the LLB in assessing liquor licence applications. When preparing the Guidelines, the LLB has also taken the opportunity to review the effectiveness of the existing licensing conditions and proposed the inclusion of new conditions as appropriate, in the interest of ensuring that the licensing mechanism remains appropriate and relevant in present-day circumstances.</p>
    <p class="mt-3">The new licensing conditions will take effect in future by phases. A brief description of these conditions and their respective inception dates are set out below.</p>
    <p class="mt-3">New Licensing conditions for "upstairs bars" <span style="color:#4e45d1">²</span></p>
    <div class="s-li">
      <div>•</div>
      <p><strong>"Seminar on Liquor Licensing"</strong><br/>With effect from 1 December 2013, upon receipt of (i) applications for new issue or transfer of liquor licence; or (ii) applications for renewal of liquor licence, the LLB will impose an additional licensing condition requiring the licensee to attend the "Seminar on Liquor Licensing" (the Seminar) within the licence period. The Seminar will cover the topics of fire safety, public order and environmental hygiene in a manner sensitive to the management needs of "upstairs bars". In general, each licensee is required to attend the Seminar once.</p>
    </div>
    <div class="s-li">
      <div>•</div>
      <p><strong>Capacity limit: safety margin</strong><br/>The LLB may, having regard to the actual circumstances and the advice of the departments concerned, consider imposing a safety margin on the capacity limit for "upstairs bars" that is more stringent, say 90% of the capacity limit that is otherwise applicable, and prescribing it as one of the licensing conditions.<br/><br/>The new requirement will come into effect on 1 June 2014. Upon receipt of applications for new issue of liquor licence on or after 1 June 2014, the LLB will consider adopting a 90% safety margin on the capacity limit as a licensing condition. For licences that already exist on or before 31 May 2014, the LLB will, when dealing with the renewal application immediately subsequent to the next renewal, consider adopting the 90% safety margin on the capacity limit as a licensing condition. This is to give ample grace period.</p>
    </div>
    <p class="mt-3">New Licensing conditions for all liquor-licenced premises</p>
    <div class="s-li">
      <div>•</div>
      <p><strong>"More stringent noise abatement measures"</strong><br/>The LLB will consider requiring liquor-licensed premises to take additional noise insulation measures in cases with serious noise problems. When considering doing so, the LLB will take into account the actual circumstances of individual premises, such as the practicability of installing double-glazed windows or double doors at the entrance on such premises as well as the availability of any other more appropriate acoustic insulation materials.<br/><br/>This new requirement will come into effect on 1 June 2014. Upon receipt of applications for new issue or renewal of liquor licence on or after 1 June 2014, the LLB will consider imposing licensing conditions regarding noise insulation which are more stringent than the existing ones in cases with serious noise problems.</p>
    </div>
    <p class="mt-3">For enquiries, please contact the LLB Secretariat or the respective Liquor Licensing Offices.</p>
    <p><strong>Liquor Licensing Board<br/>1 December 2013</strong></p>
    <ol>
      <li>The term "upstairs bars" refers to liquor-licensed premises which operate as bars with the sale and consumption of liquor on the premises being the main line of business and which are not located at the street level. These premises are mostly situated in office buildings or domestic/commercial buildings. Due to their special physical environment and the relatively greater difficulties inherent in taking enforcement actions and conducting inspections in respect of fire safety, noise, environmental hygiene and public nuisance, etc., they are prone to attract public concerns and draw complaints from the neighbourhood nearby. In general, for liquor-licensed premises that are located in shopping centres, hotels or buildings specifically designed for entertainment businesses, we do not put them under the "upstairs bars" category.</li>
      <li class="mt-8">Individual holders of liquor licences who have doubts on whether their premises fall under the category of "upstairs bars", or any person who wishes to rent or buy a building unit for operating bar business and wants to know whether it will be classified as an "upstairs bar", may make enquiries with the LLB Secretariat or the respective Liquor Licensing Offices.</li>
    </ol>
  </div>
  
</template>

<script>
import BoldLine from '../../components/misc/bold-line'
import OlsExpansion from '../../components/misc/ols-expansion'

export default {
  name: 'InGuideline',
  components: {
    BoldLine,
    OlsExpansion,
  },
  data: function () {
    return {
      items:[
          
      ],
      special:{
          order: 11,
          title:'I cannot use PPS for online payment recently. I have been using PPS payment in the past. Why does this happen and what can I do?',
          content:'<img :src="logo" alt="Error message in Internet Explorer" width="234" height="294">'
      },
    }
  },
}
</script>

<style lang="scss">
p.expansion{
  strong.i{
       font-style: italic;
  }
  span{
    word-wrap: break-word;
  }
}
a{
      text-decoration: none !important;
    p{
      color:$ols-primary !important;
    }
    line-break: anywhere;
}

.s-li{
    display: flex;
    align-items: flex-start;
    margin-bottom:12px;
    div:first-child{
        color: $ols-secondary;
        font-weight: bold;
        font-size:2em;
        margin:-6.5px 16px 0px 20px;
    }
}

.content-div h3{
  font-size:2.125em;
}

ul.expansion{
  margin-left:140px;
  
  li{
    margin-bottom:12px;
    p{
      margin:0px;
      span{
        word-wrap: break-word;
      }
    }
  }
}


img.expansion{
  margin-left:60px;
  margin-bottom:16px;
}
//md and down
@media screen and (max-width: 1263px) {
  

}

</style>